@import "minima";


body {
  max-width: 1000px;
  margin: 0 auto;
}


.page-content{
  margin-left: 200px;
 
}

//.nav {
//  align-items: center;
//}

.site-nav{
  margin-bottom: 20px;
}
.site-nav .page-link{
  color: white;
  text-align: left;
  text-decoration: underline;
}
.site-title {
  text-align: center;
  color: white;
}

.site-title:visited{
  color: white;
}

.site-title:a {
  text-align: center;
  color: white;
}

.categories-container {
  color: white;
  background-color: #4CAF50;
 }

.categories-container a{
 
  color: white;
}
.categories-container:visited{
  color: white;
}


.site-header {
  width: 200px;
  position: absolute;
  background-color: dodgerblue;
  color: white;
  text-decoration-color: white;
  text-align: center;
}

.site-footer {
  // padding-left: 20px;
  margin-left: 200px;
  background-color: #9e9e9e7a;
  // z-index: 0;
}
.p-name {
  color: rgb(29, 29, 29);
}

@media screen and (max-width: 600px) {
  .site-nav {
    border-top: 20px;
    background-color: #4CAF50;
    border-radius: 5%;
    text-align: center;
  }
  .site-header {
    width: 100%;
    height: auto;
    position: relative;
  }
  .site-header a {
    float: left;
  }

  .post-header {
    margin-left: 20px;
  }

  .post-content {
    margin-left: 20px;
  }

  .site-footer {
    margin-left: 0x;
  }

  .categories-container{
    display: none ;
  }

  .page-content {
    margin-left: 10px;

  }
}




.page-link {
  display: block;
}

.page-link:a {
  text-decoration: none;
  color: white;
}

.page-link:visited {
  color: white;
}

.trigger {
   text-align: center;
   color: white;

  }

.trigger a {
  color: white;
  text-align: center;

}



.responsive {
  width: 80%;
  height: auto;
  position: relative;
  margin-left: 15px;
  margin-top: 15px;
}

@media screen and (max-width: 700px) {
  .responsive {
    max-width: 50px;
    height: auto;
    position: relative;
    margin: 10px;
    float: left;
  }
}



.nav-trigger{
  background-color: dodgerblue;
}
